.navigation-wrapper {
  position: relative;
}

.keen-slider {
  margin-top: -20px;
  padding-top: 20px;
}

.keen-slider__slide {
  margin-top: -20px;
  padding-top: 20px;
}

.slider-mobile {
  margin-top: -20px;
  padding-top: 0px;
}

.arrow {
  width: 40px;
  height: 40px;
  padding: 30px 0px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #838c98cc;
  cursor: pointer;
}

.arrow:hover{
  fill: #A4B4BE;
}

.arrow--left {
  left: 5px;
  padding-right: 30px;
}

.arrow--right {
  left: auto;
  right: 5px;
  padding-left: 30px;
}

.arrow--hover {
  fill: rgba(255, 255, 255, 0.5);
}

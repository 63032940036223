html,
body,
#root {
  width: 100vw;
  height: 100%;
  padding: 0;
  overflow: hidden;
  margin: 0;
  overscroll-behavior: None;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (hover: none) and (pointer: coarse) {

  input,
  select,
  textarea {
    font-size: 11px;
  }

  input:focus,
  select:focus,
  textarea:focus {
    font-size: 16px;
  }
}